@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  font-family: 'Inter', sans-serif;
  background-color: #050505;
  color: #FFF;
}

p{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}