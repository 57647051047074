.menu a {
  width: 103px;
  height: 17px;
  flex-shrink: 0;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 274;
  line-height: 99%; /* 14.85px */
  letter-spacing: -0.6px;
  background: linear-gradient(90deg, #e3fdf5 0%, #88600b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.opened {
  background-color: #050505;
  z-index: 30;
}
.opened button {
  right: 0px;
  top: 0px;
  position: absolute;
}

a.link_opened {
  font-size: 30px;
  line-height: 39px;
  width: auto;
  height: auto;
  margin-top: 7px;
  margin-bottom: 7px;
}
