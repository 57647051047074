.evm_compatible {
  width: 257px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 0.3px solid #2f2a2a;
  background: #100d17;
}

.evm_compatible_text {
  background: linear-gradient(90deg, #cca219 0%, #be503f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  font-style: normal;
  font-weight: 274;
  line-height: 99%;
  letter-spacing: -0.6px;
}

.right a {
  width: 103px;
  height: 17px;
  flex-shrink: 0;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 274;
  line-height: 99%; /* 14.85px */
  letter-spacing: -0.6px;
  background: linear-gradient(90deg, #e3fdf5 0%, #ffe6fa 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
