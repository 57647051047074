/* HOME */
.gradient_background {
  background: linear-gradient(180deg, rgba(5, 5, 5, 0) -25.24%, #050505 74.1%);
}
.gradient2_background {
  background: radial-gradient(
    50.21% 48.18% at 50% 0%,
    #21133e 0%,
    rgba(5, 5, 5, 0) 100%
  );
  backdrop-filter: blur(0px);
}

.center_text span {
  background: linear-gradient(90deg, #a905f6 7.13%, #00f0ff 32.84%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.center_text {
  background: linear-gradient(90deg, #fdfbfb 0%, #ebedee 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 65px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -1px;
}

.badge {
  display: flex;
  height: 54px;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 96px;
  border: 1px solid rgba(255, 255, 255, 0.27);
}

.badge:hover {
  border-radius: 96px;
  border: 1px solid #fff;
  background: radial-gradient(
    46.56% 199.07% at 50.19% 199.07%,
    #6c08aa 0%,
    #50038d 43.8%,
    #12111e 100%
  );
}

.badge span {
  color: var(--Neutral-Colors-100, #fff);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}

.big {
  color: var(--Neutral-Colors-100, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 84px; /* 116.667% */
  letter-spacing: -2px;
}
.small {
  color: var(--Neutral-Colors-400, #afafaf);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 110% */
  text-transform: uppercase;
}

.inner_card {
  border-radius: 24px;
  background: rgba(5, 5, 5, 0.05);
  backdrop-filter: blur(10px);
}

.title {
  width: 607px;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  /* Special Headings/Display 2 */
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 66px; /* 122.222% */
  letter-spacing: -2px;
  background: var(
    --Gradient-01,
    linear-gradient(108deg, #fff 27.11%, #d8e6f7 91.68%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  width: 576.394px;
  height: 57.225px;
  flex-shrink: 0;
  color: var(--Neutral-Colors-400, #afafaf);
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  /* Paragraph/Default */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.action a {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 96px;
  border: 1px solid #fff;
  background: linear-gradient(97deg, #566070 7.45%, #060606 103.22%);
  color: var(--Primary-Colors-Color-1, #fff);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;
  /* Text Single/200/Medium */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
}